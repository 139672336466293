
import { audioFiles, bigPictureSounds }  from '@/constants/audioFiles';
import { EventBus }                      from '@/helpers/event-bus';
import { ImageList, imageListProcessor } from '@/helpers/imageListProcesor';
import { store }                         from '@/helpers/store';
import { Howl }                          from 'howler';
import { Component, Vue }                from 'vue-property-decorator';


@Component({
  components: {},
  data() {
    return {
      store,
    };
  },
})
export default class TreeHtml extends Vue {
  public imagesPerRow = 66;
  public images: ImageList = imageListProcessor(this.imagesPerRow);

  public xCurrentSize = 90;

  public minSize = 90;
  public maxSize = 250;

  private stepSize = 20;
  private soundLevel = -1;

  private isDown = false;

  private treeViewPort!: HTMLElement;
  private treeHtmlWrapper!: HTMLElement;

  private offset: number[] = [
    0,
    0,
  ];

  private imageStyle = {
    width: this.currentSize + 'px',
    height: this.currentSize + 'px',
  };
  private rowStyle = {
    height: this.currentSize + 'px',
  };

  private lastClick = {
    x: 0,
    y: 0,
  };

  private mousePosition = {
    x: 0,
    y: 0,
    relativeX: 0,
    relativeY: 0,
  };

  private sounds: Howl[] = [];

  private bigPicture!: string;
  private showBigPicture = false;

  private bigPictureSound!: Howl;
  private origOnKeyDown: ((this: GlobalEventHandlers, ev: KeyboardEvent) => any) | null = null;

  public get volume(): number {
    return store.state.volume;
  }

  public get currentSize(): number {
    return this.xCurrentSize;
  }

  public set currentSize(newSize: number) {
    this.xCurrentSize = newSize;
    this.imageStyle.width = newSize + 'px';
    this.imageStyle.height = newSize + 'px';
    this.rowStyle.height = newSize + 'px';

    const newFullWidth = (newSize * this.imagesPerRow);
    const newFullHeight = (newSize * Object.keys(this.images).length);

    this.treeViewPort.style.width = newFullWidth + 'px';
    this.treeViewPort.style.height = newFullHeight + 'px';
  }

  public get playLevelSound(): number {
    return this.xCurrentSize;
  }

  public set playLevelSound(level: number) {
    if (this.soundLevel === level) {
      return;
    }

    if (!this.sounds[level]) {
      this.sounds[level] = new Howl({
        src: [audioFiles[level]],
        autoplay: true,
        loop: true,
        volume: 0,
      });
    }

    if (this.soundLevel >= 0) {
      this.sounds[this.soundLevel].fade(this.volume, 0, 1000)
        .pause();
    }

    this.sounds[level].fade(0, this.volume, 1000)
      .play();
    this.soundLevel = level;
  }

  public get sizeRanges(): number[][] {
    const ranges: number[][] = [];

    let currentSize: number = this.minSize;

    while (currentSize <= this.maxSize) {
      ranges.push([
        currentSize,
        currentSize + this.stepSize,
      ]);

      currentSize += this.stepSize;
    }

    return ranges;
  }

  public get currentFullWidth(): number {
    return (this.currentSize * this.imagesPerRow);
  }

  public get currentFullHeight(): number {
    return (this.currentSize * Object.keys(this.images).length);
  }

  public get treeHtmlWrapperWidth(): number {
    return this.treeHtmlWrapper.getBoundingClientRect().width;
  }

  public get treeHtmlWrapperHeight(): number {
    return this.treeHtmlWrapper.getBoundingClientRect().height;
  }

  private mouseDown($event: MouseEvent): void {
    if ($event.button !== 0) {
      return;
    }

    this.isDown = true;
    this.offset = [
      this.treeViewPort.getBoundingClientRect().x - $event.pageX,
      this.treeViewPort.getBoundingClientRect().y - $event.pageY,
    ];
    this.lastClick.x = $event.x;
    this.lastClick.y = $event.y;
  }

  private wheel($event: WheelEvent): void {
    const mac = navigator.platform.indexOf('Mac') > -1;
    const zoom: number = mac ? ($event.deltaY > 0 ? 1 : -1) : ($event.deltaY < 0 ? 1 : -1);

    if (!zoom) {
      return;
    }

    let maxScale = false;
    const curSizeRatio = Math.min(this.currentFullWidth / this.treeHtmlWrapperWidth, this.currentFullHeight / this.treeHtmlWrapperHeight);
    let newSize = this.currentSize + (zoom * this.stepSize);
    const newSizeRatio = this._getNewSizeRatio(newSize);

    if (newSize < this.minSize) {
      newSize = this.minSize;
      maxScale = true;
    }

    if (newSize > this.maxSize) {
      newSize = this.maxSize;
      maxScale = true;
    }

    this.currentSize = newSize;

    if (maxScale) {
      this._detectAndFixOutOfBounds();

      return;
    }

    this.playLevelSound = this.sizeRanges.findIndex(
      (levelRange: number[]) => newSize >= levelRange[0] && newSize < levelRange[1],
    );

    this._followMouseOnZoom($event, curSizeRatio, newSizeRatio);

    this._detectAndFixOutOfBounds();
  }

  private mouseUp($event: MouseEvent): void {
    if ($event.button !== 0) {
      return;
    }

    this.isDown = false;

    // works, disabled for now

    const current = {
      x: $event.x,
      y: $event.y,
    };

    const element = $event.target as HTMLElement;

    const releasedMouseInSamePosition: boolean = (
      Math.abs(this.lastClick.x - current.x) <= 5 &&
      Math.abs(this.lastClick.y - current.y) <= 5
    );

    if (releasedMouseInSamePosition) {
      this._openBigPicture(element);
    }
  }

  private mouseMove($event: MouseEvent): void {
    this.mousePosition.x = $event.clientX;
    this.mousePosition.y = $event.clientY;
    this.mousePosition.relativeX = $event.pageX - this.treeViewPort.getBoundingClientRect().left;
    this.mousePosition.relativeY = $event.pageY - this.treeViewPort.getBoundingClientRect().top;

    if (this.isDown) {
      this.treeViewPort.style.left = (this.mousePosition.x + this.offset[0]) + 'px';
      this.treeViewPort.style.top = (this.mousePosition.y + this.offset[1]) + 'px';

      this._detectAndFixOutOfBounds();
    }
  }

  private mounted() {
    this.treeViewPort = this.$refs['tree-view-port'] as HTMLElement;
    this.treeHtmlWrapper = this.$refs['tree-html-wrapper'] as HTMLElement;
    this.treeViewPort.style.width = this.currentFullWidth + 'px';
    this.treeViewPort.style.height = this.currentFullHeight + 'px';

    const treeHtmlWrapperWidth = this.treeHtmlWrapper.getBoundingClientRect().width;
    const treeHtmlWrapperHeight = this.treeHtmlWrapper.getBoundingClientRect().height;
    const widthDiff = this.currentFullWidth - treeHtmlWrapperWidth;
    const heightDiff = this.currentFullHeight - treeHtmlWrapperHeight;

    const newLeft = -(widthDiff / 2);
    const newTop = -(heightDiff / 2);

    this.treeViewPort.style.left = newLeft + 'px';
    this.treeViewPort.style.top = newTop + 'px';

    this._detectAndFixOutOfBounds();

    this.playLevelSound = 0;

    this.origOnKeyDown = document.onkeydown;
    document.onkeydown = ($event) => {
      $event = $event || window.event;
      if ($event.key === 'Escape') {
        this.closeBigPicture();
      }
    };

    EventBus.$on('volume-changed', () => {
      if (this.sounds[this.soundLevel]) {
        this.sounds[this.soundLevel].volume(this.volume);
      }

      if (this.bigPictureSound) {
        this.bigPictureSound.volume(this.volume);
      }
    });
  }

  private onDeactivated() {
    this.sounds.forEach((sound) => sound?.stop());

    EventBus.$on('volume-changed', () => {
      return;
    });

    if (this.origOnKeyDown) {
      document.onkeydown = this.origOnKeyDown;
    }
  }

  private closeBigPicture(): void {
    if (this.showBigPicture) {
      this.showBigPicture = false;

      this.sounds[this.soundLevel].play();
      this.sounds[this.soundLevel].fade(0, this.volume, 1000);

      this.bigPictureSound.fade(this.volume, 0, 1000)
        .stop();
    }
  }

  private _openBigPicture(element: HTMLElement): void {
    const elementData = JSON.parse(element.getAttribute('data-image') as string);
    this.bigPicture = elementData.bigImage;
    this.showBigPicture = true;

    this.sounds[this.soundLevel].fade(this.volume, 0, 1000)
      .pause();

    this.bigPictureSound = new Howl({
      src: [bigPictureSounds[elementData.group]],
      autoplay: true,
      loop: true,
      volume: 0,
    });
    this.bigPictureSound.fade(0, this.volume, 1000);
  }

  private _getNewSizeRatio(newSize: number): number {
    const newFullWidth = (newSize * this.imagesPerRow);
    const newFullHeight = (newSize * Object.keys(this.images).length);

    return Math.min(newFullWidth / this.treeHtmlWrapperWidth, newFullHeight / this.treeHtmlWrapperHeight);
  }

  private _detectAndFixOutOfBounds(): void {
    if (this.treeViewPort.getBoundingClientRect().left > 0) {
      this.treeViewPort.style.left = '0px';
    }

    if (this.treeViewPort.getBoundingClientRect().top > 0) {
      this.treeViewPort.style.top = '0px';
    }

    const widthDiff = this.treeHtmlWrapperWidth - this.currentFullWidth;
    const heightDiff = this.treeHtmlWrapperHeight - this.currentFullHeight;
    const left = this.treeViewPort.getBoundingClientRect().left;
    const top = this.treeViewPort.getBoundingClientRect().top;

    if (left < widthDiff) {
      this.treeViewPort.style.left = widthDiff + 'px';
    }

    if (top < heightDiff) {
      this.treeViewPort.style.top = heightDiff + 'px';
    }
  }

  private _followMouseOnZoom(
    $event: WheelEvent,
    curSizeRatio: number,
    newSizeRatio: number,
  ): void {
    const currentImageOffset = {
      x: this.treeViewPort.getBoundingClientRect().left,
      y: this.treeViewPort.getBoundingClientRect().top,
    };

    const imageLoc = {
      x: $event.pageX - currentImageOffset.x,
      y: $event.pageY - currentImageOffset.y,
    };

    const zoomPoint = {
      x: imageLoc.x / curSizeRatio,
      y: imageLoc.y / curSizeRatio,
    };

    const zoomPointNew = {
      x: zoomPoint.x * newSizeRatio,
      y: zoomPoint.y * newSizeRatio,
    };

    const newScroll = {
      x: zoomPointNew.x - $event.pageX,
      y: zoomPointNew.y - $event.pageY,
    };

    this.treeViewPort.style.left = -newScroll.x + 'px';
    this.treeViewPort.style.top = -newScroll.y + 'px';
  }
}
