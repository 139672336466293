export const audioFiles: string[] = [
  require('@/assets/sound/sound1.mp3'),
  require('@/assets/sound/sound2.mp3'),
  require('@/assets/sound/sound3.mp3'),
  require('@/assets/sound/sound4.mp3'),
  require('@/assets/sound/sound5.mp3'),
  require('@/assets/sound/sound6.mp3'),
  require('@/assets/sound/sound6.mp3'),
  require('@/assets/sound/sound7.mp3'),
  require('@/assets/sound/sound8.mp3'),
  require('@/assets/sound/sound9.mp3'),
  require('@/assets/sound/sound10.mp3'),
];

export const bigPictureSounds: string[] = [
  require('@/assets/soundForEachTreeGroup/s0.mp3'),
  require('@/assets/soundForEachTreeGroup/s2.mp3'),
  require('@/assets/soundForEachTreeGroup/s3.mp3'),
  require('@/assets/soundForEachTreeGroup/s4.mp3'),
  require('@/assets/soundForEachTreeGroup/s5.mp3'),
  require('@/assets/soundForEachTreeGroup/s6.mp3'),
  require('@/assets/soundForEachTreeGroup/s7.mp3'),
  require('@/assets/soundForEachTreeGroup/s8.mp3'),
  require('@/assets/soundForEachTreeGroup/s9.mp3'),
  require('@/assets/soundForEachTreeGroup/s10.mp3'),
  require('@/assets/soundForEachTreeGroup/s11.mp3'),
  require('@/assets/soundForEachTreeGroup/s12.mp3'),
  require('@/assets/soundForEachTreeGroup/s13.mp3'),
  require('@/assets/soundForEachTreeGroup/s14.mp3'),
  require('@/assets/soundForEachTreeGroup/s15.mp3'),
  require('@/assets/soundForEachTreeGroup/s16.mp3'),
  require('@/assets/soundForEachTreeGroup/s17.mp3'),
  require('@/assets/soundForEachTreeGroup/s18.mp3'),
  require('@/assets/soundForEachTreeGroup/s19.mp3'),
  require('@/assets/soundForEachTreeGroup/s20.mp3'),
  require('@/assets/soundForEachTreeGroup/s21.mp3'),
  require('@/assets/soundForEachTreeGroup/s22.mp3'),
  require('@/assets/soundForEachTreeGroup/s23.mp3'),
  require('@/assets/soundForEachTreeGroup/s24.mp3'),
  require('@/assets/soundForEachTreeGroup/s25.mp3'),
  require('@/assets/soundForEachTreeGroup/s26.mp3'),
  require('@/assets/soundForEachTreeGroup/s27.mp3'),
  require('@/assets/soundForEachTreeGroup/s28.mp3'),
  require('@/assets/soundForEachTreeGroup/s29.mp3'),
  require('@/assets/soundForEachTreeGroup/s30.mp3'),
  require('@/assets/soundForEachTreeGroup/s31.mp3'),
  require('@/assets/soundForEachTreeGroup/s32.mp3'),
  require('@/assets/soundForEachTreeGroup/s33.mp3'),
  require('@/assets/soundForEachTreeGroup/s34.mp3'),
  require('@/assets/soundForEachTreeGroup/s35.mp3'),
  require('@/assets/soundForEachTreeGroup/s36.mp3'),
  require('@/assets/soundForEachTreeGroup/s37.mp3'),
  require('@/assets/soundForEachTreeGroup/s38.mp3'),
  require('@/assets/soundForEachTreeGroup/s39.mp3'),
];
