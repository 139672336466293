
import TreeHtml           from '@/components/tree-html.vue';
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
    TreeHtml,
  },
})
export default class TreeView extends Vue {
}
