import { imagesList } from '@/constants/small-images';


export interface ImageGroup {
  smallImage: string;
  bigImage: string;
  group: string;
}

export interface ImageList {
  [key: number]: ImageGroup[];
}

export function imageListProcessor(imagesPerRow: number) {
  const items: ImageList = {
    0: [],
  };

  let groupNum = 0;
  let imgCountInGroup = 0;
  let group: ImageGroup[] = [];

  for (let i = 0; i <= imagesList.length - 1; i++) {
    const picGroupNum = imagesList[i].split('_')[0];
    const [fileName, ext] = imagesList[i].split('.');

    group.push({
      smallImage: imagesList[i],
      bigImage: fileName + '_big.' + ext,
      group: picGroupNum,
    });
    imgCountInGroup++;

    if (imgCountInGroup === imagesPerRow) {
      items[groupNum] = group;
      groupNum++;
      group = [];
      imgCountInGroup = 0;
    }
  }

  return items;
}
